import React, { useState, useEffect } from 'react';
import './Welcome.css';
import CadastroOk from './CadastroOk';

export default function Welcome(props) {

    return (
        <div style={{ backgroundColor: '#F5F5F5', minHeight: '100vh', height: '100%' }}>
            <CadastroOk />
        </div>
    )
}
