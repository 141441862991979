import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

export default function Loading() {
    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#F5F5F5', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            <div style={{ alignSelf: 'center' }}>
                <div style={{textAlign: 'center', backgroundColor: 'white', padding: '50px', borderRadius: '20px'}}>
                    <CircularProgress size={100} />
                    <Typography variant="h4" className="mt-5">Carregando...</Typography>
                </div>
            </div>
        </div>
    )
}
