import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './Ativacao.css';
import AtivacaoBemSucedida from './AtivacaoBemSucedida';
import CadastroNaoLocalizado from './CadastroNaoLocalizado';
import EmailJaConfirmado from './EmailJaConfirmado';
import { AtivacaoLead } from '../../services/LeadService';
import Loading from '../../components/Loading';
import Error from '../../components/Error';

export default function Welcome(props) {

    const [carregando, setCarregando] = useState(true);
    const [erro, setErro] = useState(false);
    const [view, setView] = useState("");

    useEffect(() => {
        var keyUser = "";

        var paramUrl = new URLSearchParams(props.location.search).get("k");
        if (paramUrl)
            keyUser = paramUrl;

        async function FetchApi() {
            try {
                var data = await AtivacaoLead(keyUser ? keyUser : 'nao-informado');

                if (data.data && data.data.message) {
                    const message = data.data.message;

                    let error = false;
                    if (message === 'Cadastro não localizado') {
                        setView('CadastroNaoLocalizado');
                    } else if (message === 'Email já foi confirmado') {
                        setView('EmailJaConfirmado');
                    } else if (message === 'Email confirmado') {
                        setView('AtivacaoBemSucedida');
                    } else {
                        error = true;
                    }

                    setCarregando(false);
                    setErro(error);
                } else {
                    setCarregando(false);
                    setErro(true);
                }
            } catch (error) {
                setCarregando(false);
                setErro(true);
            }
        }

        FetchApi();
    }, [])

    return (
        <div style={{ backgroundColor: '#F5F5F5', minHeight: '100vh', height: '100%' }}>
            {carregando && <Loading />}
            {erro && <Error />}
            {!carregando && !erro && view === "CadastroNaoLocalizado" && <CadastroNaoLocalizado />}
            {!carregando && !erro && view === "EmailJaConfirmado" && <EmailJaConfirmado />}
            {!carregando && !erro && view === "AtivacaoBemSucedida" && <AtivacaoBemSucedida />}
        </div>
    )
}
