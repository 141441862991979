import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { GetOrigem } from "../../services/OrigemService";
import Banner from "./Banner";
import Loading from "../../components/Loading";
import Error from "../../components/Error";

export default function Home(props) {
    const [carregando, setCarregando] = useState(true);
    const [erro, setErro] = useState(false);
    const [origem, setOrigem] = useState({});

    useEffect(() => {
        
        let nomeOrigem = '';
        var paramUrl = new URLSearchParams(props.location.search).get("o");
        if (paramUrl && paramUrl != nomeOrigem && typeof paramUrl === 'string')
            nomeOrigem = paramUrl;

        async function FetchApi() {
            try {
                console.log('nomeOrigem', nomeOrigem, typeof nomeOrigem);

                const linkOrigem = nomeOrigem && typeof nomeOrigem === 'string' ? nomeOrigem : "nao-informado";
                //console.log('Load', linkOrigem);
                var data = await GetOrigem(linkOrigem);

                setCarregando(false);
                setErro(false);
                setOrigem(data);
            } catch (error) {
                setCarregando(false);
                setErro(true);
            }
        }

        FetchApi();
    }, []);

    return (
        <div style={{ backgroundColor: '#F5F5F5', minHeight: '100vh', height: '100%' }}>
            {carregando && <Loading />}
            {erro && <Error />}
            {!carregando && !erro && <Banner origem={origem} />}
        </div>
    )
}
