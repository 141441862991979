export const ValidarTelefone = (telefone) => {
    if (!telefone)
        return false;

    const ddds = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27,
        28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45,
        46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64, 65,
        66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97,
        98, 99];

    let numeroWpp = telefone.replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(/_/g, "")
        .replace(/ /g, "");


    for (let index = 0; index < numeroWpp.length; index++) {
        const element = numeroWpp[index];

        if (!isInt(element))
            return false;
    }

    var ddd = numeroWpp.substring(0, 2);

    var nonoDigito = numeroWpp.substring(2, 3);

    if (parseInt(nonoDigito) != 9)
        return false;

    if (!ddds.includes(parseInt(ddd)))
        return false;

    if (numeroWpp.length != 11)
        return false;

    return true;
}

const isInt = (value) => {
    return !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10));
}