import { Http } from '../config/Http';

export const PostLead = async (lead) => {
    //return Http.post(`advogado`, lead);
    return Http.post(`lead`, lead);
}

export const AtivacaoLead = async (key) => {
    //return Http.post(`advogado`, lead);
    return Http.get(`lead/ativacao/${key}`);
}