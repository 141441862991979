import React from 'react';
import './Welcome.css';
import { FaFacebookF, FaInstagram, FaWhatsapp, FaYoutube,  FaEnvelope } from 'react-icons/fa';

export default function AtivacaoBemSucedida() {
    return (
        <div className="divMain">
            <table border="0" className="tableFormat" width="100%">
                <tbody>
                    <tr>
                        <td bgcolor="#156aa9" align="center">
                            <table border="0" width="100%" className="maxWidth600 tableFormat">
                                <tbody>
                                    <tr>
                                        <td align="center" valign="top" className="tdStyle1"> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#156aa9" align="center" className="tdStyle2">
                            <table border="0" width="100%" className="maxWidth600 tableFormat">
                                <tbody>
                                    <tr>
                                        <td bgcolor="#ffffff" align="center" valign="top" className="tdStyle3">
                                            <h1 className="h1Style2">Cadastro realizado com sucesso!</h1> 
                                            <img src="/fire-cracker.png" className="h1Style" alt="Iamgem Sucesso" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#f4f4f4" align="center" className="tdStyle7">
                            <table border="0" width="100%" className="maxWidth600 tableFormat">
                                <tbody>
                                    <tr>
                                        <td bgcolor="#ffffff" align="left" className="tdStyle4">
                                            <p className="margin0">Para finalizar a configuração do seu ambiente acesse seu e-mail para confirmar o seu cadastro.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td bgcolor="#ffffff" align="left" className="tdStyle5">
                                            <p className="margin0">Lembre-se de verificar sua caixa de spam ou lixeira!</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td bgcolor="#ffffff" align="left" className="tdStyle6">
                                            <p className="margin0">Time Advo Jurídico.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#f4f4f4" align="center" className="tdStyle8">
                            <table border="0" width="100%" className="maxWidth600 tableFormat">
                                <tbody>
                                    <tr>
                                        <td bgcolor="#FFECD1" align="center" className="tdStyle9">
                                            <h2 className="h2Style">Precisa de ajuda?</h2>
                                            <p className="margin0"><a href="https://api.whatsapp.com/send?phone=5527992434224" rel="noreferrer" target="_blank" className="corLaranja">Estamos aqui para ajudá-lo</a></p>

                                            <ul className="list-unstyled list-inline social text-center ulStyle">
                                                <li className="list-inline-item"><a href="https://www.facebook.com/advojuridico/" rel="noreferrer" target="_blank"><FaFacebookF className="corLaranja" /></a></li>
                                                <li className="list-inline-item"><a href="https://www.instagram.com/advojuridico/" rel="noreferrer" target="_blank"><FaInstagram className="corLaranja" /></a></li>
                                                <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCYVUXsakrKl0y8yAjVanlpw" rel="noreferrer" target="_blank"><FaYoutube className="corLaranja" /></a></li>
                                                <li className="list-inline-item"><a href="https://api.whatsapp.com/send?phone=5527992434224" rel="noreferrer" target="_blank"><FaWhatsapp className="corLaranja" /></a></li>
                                                <li className="list-inline-item"><a href="mailto:suporte@advo.work" rel="noreferrer" target="_blank"><FaEnvelope className="corLaranja" /></a></li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
