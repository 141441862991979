import React from 'react';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import { IoSend } from 'react-icons/io5';

export default function Loading() {
    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#F5F5F5', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            <div style={{ alignSelf: 'center' }}>
                <div style={{ textAlign: 'center', backgroundColor: 'white', padding: '50px', borderRadius: '20px' }}>
                    <img src="/warning.png" />
                    <Typography variant="h4" className="mt-5">Não foi possivel carregar a página!</Typography>
                    <Typography variant="subtitle1" className="mt-1" style={{ color: '#6c757d' }}>Atualize a página e tente novamente, caso o erro persista entre em<br /> contato com o nosso suporte.</Typography>
                    <Button variant="contained" color="secondary" className="mt-3" href="https://api.whatsapp.com/send?phone=5527992434224" rel="noreferrer" target="_blank" startIcon={<IoSend />}>
                        Suporte
                    </Button>
                </div>
            </div>
        </div>
    )
}
