import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  LinearProgress,
  Chip,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import ReactPixel from "react-facebook-pixel";
import { GetForcaSenha } from "../../util/ForcaSenha";
import {
  IoLogoWhatsapp,
  IoMailOutline,
  IoEyeOutline,
  IoEyeOffOutline,
} from "react-icons/io5";
import { MdVpnKey, MdArrowForward } from "react-icons/md";
import { HiRefresh } from "react-icons/hi";
import { ValidarTelefone } from "../../util/ValidarTelefone";
import { PostLead } from "../../services/LeadService";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function FormCliente(props) {
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [oab, setOab] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [nomeEscritorio, setNomeEscritorio] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaHidden, setSenhaHidden] = useState(true);
  const [senhaConfirmacaoSenhaHidden, setSenhaConfirmacaoSenhaHidden] =
    useState(true);
  const [confirmaSenha, setConfirmarSenha] = useState("");
  const [pontosSenha, setpontosSenha] = useState(0);
  const [enviandoDados, setEnviandoDados] = useState(false);
  const [receberEmail, setReceberEmail] = useState(true);

  let history = useHistory();

  const switchSenhaHidden = () => {
    setSenhaHidden(!senhaHidden);
  };

  const switchConfirmacaoSenhaHidden = () => {
    setSenhaConfirmacaoSenhaHidden(!senhaConfirmacaoSenhaHidden);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validarEmail = () => {
    if (email && !validateEmail(email)) {
      return {
        erro: true,
        mensagem: "E-mail inválido",
      };
    }

    return {
      erro: false,
      mensagem: "",
    };
  };

  const validarCampoTelefone = () => {
    if (whatsapp && !ValidarTelefone(whatsapp)) {
      return {
        erro: true,
        mensagem: "Whatsapp inválido",
      };
    }

    return {
      erro: false,
      mensagem: "",
    };
  };

  const validarConfirmacaoSenha = () => {
    if (confirmaSenha && senha != confirmaSenha) {
      return {
        erro: true,
        mensagem: "Senhas não conferem",
      };
    }

    return {
      erro: false,
      mensagem: "",
    };
  };

  const notifyAlert = (message) =>
    toast(`⚠️ ${message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (message) =>
    toast.success(`🚀 ${message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyWarn = (message) =>
    toast.warn(`💥 ${message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const enviarDados = async () => {
    setEnviandoDados(true);
    toast.dismiss();

    let alertas = [];

    if (!nomeCompleto) alertas.push("Preencha corretamente o campo nome");

    if (!oab) alertas.push("Preencha corretamente o campo OAB");

    if (!whatsapp || !ValidarTelefone(whatsapp))
      alertas.push("Preencha corretamente o campo Whatsapp");

    if (!nomeEscritorio)
      alertas.push("Preencha corretamente o campo Nome Escritório");

    if (!email || !validateEmail(email))
      alertas.push("Preencha corretamente o campo Email");

    let pontos = GetForcaSenha(senha);
    if (!senha) alertas.push("Preencha corretamente o campo Senha");
    else if (pontos.pontos < 60)
      alertas.push(
        "Preencha corretamente o campo Senha, pois não atende aos requisitos minímos de segurança"
      );

    if (!confirmaSenha)
      alertas.push("Preencha corretamente o campo Confirmação da senha");
    else if (senha != confirmaSenha)
      alertas.push("O campo confirmação de senha não confere");

    alertas.forEach((mensagem) => {
      notifyAlert(mensagem);
    });

    if (alertas.length > 0) {
      setEnviandoDados(false);
      return;
    }

    let obj = {
      NomeCompleto: nomeCompleto,
      Email: email,
      Senha: senha,
      Instagram: null,
      Whatsapp: whatsapp,
      OAB: oab,
      CPF: null,
      Secretario: false,
      TemFerramentaPublicacao: false,
      FerramentaPublicacao: false,
      NomeEscritorio: nomeEscritorio,
      CEPEscritorio: null,
      UFEscritorio: null,
      CidadeEscritorio: null,
      BairroEscritorio: null,
      RuaEscritorio: null,
      NumeroEscritorio: null,
      Origem: props.origem.descricao,
      UsuarioCadastro: "lead",
      AceitaReceberEmail: receberEmail,
    };

    var result = await PostLead(obj)
      .then(function (response) {
        ReactPixel.track("Lead", {});
        notifySuccess("Cadastro realizado com sucesso!");
        //console.log('sucesso', response);

        history.push("/welcome");
      })
      .catch(function (error) {
        notifyAlert(
          "Não foi possivel realizar o seu cadastro! Verifique o preenchimento dos campos e tente novamente..."
        );
        //console.log('erro', error.data);
      });
    setEnviandoDados(false);
    console.log(result);
  };

  useEffect(() => {
    const advancedMatching = {};
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init("1857835271055949", advancedMatching, options);
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    let pontos = GetForcaSenha(senha);
    setpontosSenha(pontos);
  }, [senha]);

  return (
    <>
      <ToastContainer />

      <TextField
        label="Nome Completo"
        type="text"
        margin="normal"
        value={nomeCompleto}
        onChange={(text) => setNomeCompleto(text.target.value)}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextField
            label="OAB"
            type="text"
            margin="normal"
            value={oab}
            onChange={(text) => setOab(text.target.value)}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <InputMask
            mask="(99) 9 9999-9999"
            value={whatsapp}
            onChange={(text) => setWhatsapp(text.target.value)}
          >
            {(inputProps) => (
              <TextField
                error={validarCampoTelefone().erro}
                helperText={validarCampoTelefone().mensagem}
                {...inputProps}
                label="Whatsapp"
                type="text"
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IoLogoWhatsapp />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </InputMask>
        </Grid>
      </Grid>

      <TextField
        label="Nome Escritório"
        type="text"
        margin="normal"
        value={nomeEscritorio}
        onChange={(text) => setNomeEscritorio(text.target.value)}
      />

      <TextField
        error={validarEmail().erro}
        helperText={validarEmail().mensagem}
        label="E-mail"
        type="email"
        autoComplete="email"
        margin="normal"
        value={email}
        onChange={(text) => setEmail(text.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IoMailOutline />
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextField
            label="Escolha uma senha de acesso"
            type={senhaHidden ? "password" : "text"}
            margin="normal"
            value={senha}
            onChange={(text) => setSenha(text.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => switchSenhaHidden()}
                  style={{ cursor: "pointer" }}
                >
                  {senhaHidden ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </InputAdornment>
              ),
            }}
          />

          {senha.length > 0 && (
            <LinearProgress
              variant="determinate"
              value={pontosSenha.pontos > 100 ? 100 : pontosSenha.pontos}
              color={pontosSenha.pontos >= 60 ? "primary" : "secondary"}
            />
          )}

          {senha.length > 0 && (
            <div style={{ marginTop: "5px" }}>
              <Chip
                icon={<MdVpnKey />}
                label={`Força senha: ${pontosSenha.forca}`}
                color={pontosSenha.pontos >= 60 ? "primary" : "secondary"}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={validarConfirmacaoSenha().erro}
            helperText={validarConfirmacaoSenha().mensagem}
            label="Confirmar Senha"
            type={senhaConfirmacaoSenhaHidden ? "password" : "text"}
            margin="normal"
            value={confirmaSenha}
            onChange={(text) => setConfirmarSenha(text.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => switchConfirmacaoSenhaHidden()}
                  style={{ cursor: "pointer" }}
                >
                  {senhaConfirmacaoSenhaHidden ? (
                    <IoEyeOutline />
                  ) : (
                    <IoEyeOffOutline />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={receberEmail}
            onChange={(check) => setReceberEmail(check.target.checked)}
            name="checkedB"
            color="primary"
          />
        }
        style={{ marginBottom: 0 }}
        label="Aceito receber novidades e promoções por e-mail"
      />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        size="large"
        className="mt-4 mb-4"
        disabled={enviandoDados}
        onClick={() => enviarDados()}
        style={{ marginBottom: 1 }}
      >
        {enviandoDados && (
          <HiRefresh className="icon-spin" style={{ marginRight: "5px" }} />
        )}
        {enviandoDados ? "Aguarde..." : "ENTRE PARA ADVOCACIA DOS GIGANTES"}
      </Button>

      <div style={{ textAlign: "center" }}>
        <a href="https://app.advojuridico.com/">
          Já é cliente? clique aqui para acessar seu ambiente
          <MdArrowForward />
        </a>
      </div>
    </>
  );
}
