import React from 'react';
import FormCliente from "./FormCliente";
import { Grid, Hidden } from '@material-ui/core';

import './Banner.css';

export default function Banner(props) {
    return (
        <Grid container spacing={0}>
            <Hidden smDown>
                <Grid item md={4} style={{ marginTop: '20px', paddingLeft: '20px' }}>
                    <div style={{
                        height: 'calc(100vh - 40px)',
                        borderRadius: '20px',
                        backgroundColor: props.origem.corFundo ? props.origem.corFundo : '#156AA8',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url(${props.origem.imagem})`
                    }}>
                    </div>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid item md={4} style={{ marginTop: '20px', marginLeft: '20px', marginRight: '20px' }}>
                    <div className="scrollContainer" style={{
                        height: 'calc(100vh - 40px)',
                        borderRadius: '20px',
                        width: 'calc(100vw - 40px)',
                        backgroundColor: props.origem.corFundo ? props.origem.corFundo : '#156AA8',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url(${props.origem.imagem})`
                    }}>
                        <img src="/scroll-down.gif" alt="scroll down" height="100" width="100" className="scrollImg" />
                    </div>
                </Grid>
            </Hidden>
            <Grid item md={8} className="gridFormClienteExterno">
                <Grid container justify="center" className="gridFormClienteInterno" >
                    <Grid item xs={12} lg={12} className="formularioCliente">
                        <h2 style={{ color: "#156AA9", textAlign: 'center' }}>{props.origem.titulo}</h2>
                        <h4 style={{ textAlign: 'center' }}>{props.origem.subTitulo}</h4>
                    </Grid>
                    <Grid item xs={10} lg={7}>
                        <FormCliente origem={props.origem} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
