export const GetForcaSenha = (senha) => {
    if (!senha)
        return { pontos: 0, forca: 'Inaceitável' };

    console.clear();

    let pontosPorTamanho = GetPontoPorTamanho(senha);
    let pontosPorMinusculas = GetPontoPorMinusculas(senha);
    let pontosPorMaiusculas = GetPontoPorMaiusculas(senha);
    let pontosPorDigitos = GetPontoPorDigitos(senha);
    let pontosPorSimbolos = GetPontoPorSimbolos(senha);
    let pontosPorRepeticao = GetPontoPorRepeticao(senha);

    let pontosDiversidade = 0;
    pontosDiversidade += pontosPorMinusculas >= 1 ? 1 : 0;
    pontosDiversidade += pontosPorMaiusculas >= 1 ? 1 : 0;
    pontosDiversidade += pontosPorDigitos >= 1 ? 1 : 0;
    pontosDiversidade += pontosPorSimbolos >= 1 ? 1 : 0;

    pontosDiversidade = pontosDiversidade * 5;

    let pontos = pontosPorTamanho + pontosPorMinusculas + pontosPorMaiusculas + pontosPorDigitos + pontosPorSimbolos + pontosDiversidade - pontosPorRepeticao;

    return { pontos: pontos, forca: GetForcaDaSenha(pontos) };
}

const GetPontoPorTamanho = (senha) => {
    return Math.min(10, senha.length) * 6;
}

const GetPontoPorMinusculas = (senha) => {
    let result = senha.replace(/[a-z]/g, '');
    result = result ? result.length : 0;

    let rawPlacar = senha.length - result;

    return Math.min(2, rawPlacar) * 5;
}

const GetPontoPorMaiusculas = (senha) => {
    let result = senha.replace(/[A-Z]/g, '');
    result = result ? result.length : 0;

    let rawPlacar = senha.length - result;

    return Math.min(2, rawPlacar) * 5;
}

const GetPontoPorDigitos = (senha) => {
    let result = senha.replace(/[0-9]/g, '');
    result = result ? result.length : 0;

    let rawPlacar = senha.length - result;

    return Math.min(2, rawPlacar) * 5;
}

const GetPontoPorSimbolos = (senha) => {
    let result = senha.replace(/[a-zA-Z0-9]/g, '');
    result = result ? result.length : 0;
    let rawPlacar = result;

    return Math.min(2, rawPlacar) * 5;
}

const GetPontoPorRepeticao = (senha) => {
    let result = findRepeat(senha);
    result = result ? result.length : 0;

    if (result > 0)
        return 15;
    else
        return 0;
}

function findRepeat(str) {
    const arr = str.split('');
    const hash = new Map();
    const result = [];
    // If repeat the value is false, if no repeat the value is true
    for (let i = 0; i < arr.length; i++) {
        if (hash.get(arr[i]) === undefined) {
            hash.set(arr[i], true);
        } else {
            const value = hash.get(arr[i]);
            if (value) {
                hash.set(arr[i], !value);
            }
        }
    }
    hash.forEach((v, k) => {
        if (!v)
            result.push(k);
    });
    return result;
}

const GetForcaDaSenha = (pontos) => {
    if (pontos < 50)
        return 'Inaceitável';
    else if (pontos < 60)
        return 'Fraca';
    else if (pontos < 80)
        return 'Aceitável';
    else if (pontos < 100)
        return 'Forte';
    else
        return 'Segura';
}