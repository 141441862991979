import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Ativacao from './pages/Ativacao';
import Welcome from './pages/Welcome';

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/welcome" exact component={Welcome} />
                <Route path="/ativacao" exact component={Ativacao} />
                <Route path="/" exact component={Home} />
                <Route component={() => <h1>Not Found</h1>} />
            </Switch>
        </BrowserRouter>
    );
}